import React, { useState, useEffect } from 'react'
import Button from '../Button'
import './style.scss'

function Reviews() {
  const [reviews, setReviews] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('https://s-manucure.vercel.app/reviews')
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => setError(error))
  }, [])

  if (error) {
    return <div>Erreur: {error.message}</div>
  }

  return (
    <div className="reviews-page">
      <h1>Avis des clientes</h1>
      {reviews.length > 0 ? (
        <div className="reviews-list">
          {reviews.map((review, index) => (
            <div key={index} className="review-card">
              <div className="review-header">
                <div className="review-avatar">
                  {review.profile_photo_url ? (
                    <img
                      src={review.profile_photo_url}
                      alt={`Avatar de ${review.author_name}`}
                    />
                  ) : (
                    <div className="default-avatar">
                      {review.author_name.charAt(0)}
                    </div>
                  )}
                </div>

                <div className="review-author">
                  <h2>{review.author_name}</h2>

                  {review.rating ? (
                    <p className="review-rating">Note: {review.rating}/5</p>
                  ) : (
                    <p className="review-rating"> Note: N/A</p>
                  )}
                </div>
              </div>
              <p className="review-text">{review.text}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>Aucun avis disponible.</p>
      )}

      <a
        href="https://g.page/r/CYU4N_cahKQSEBE/review"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="add-review">
          <span>Ajouter un avis</span>
        </Button>
      </a>
    </div>
  )
}

export default Reviews
