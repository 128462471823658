import Homepic from '../../assets/images/presentation.webp'
import Button from '../Button'
import './style.scss'

function Home() {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>Sarah Manucure</h1>
        <span className="city-h1">Saint Georges de Commiers</span>

        <div className="home-text">
          <p>
            Hello ! Moi, c’est Sarah. J’ai travaillé pendant 8 ans dans le
            secteur de la vente et maintenant, j’ai fait le choix de me
            reconvertir en tant que prothésiste ongulaire. J’ai toujours été
            passionée par le milieu de l’onglerie et j’ai choisi enfin de sauter
            le pas !
          </p>

          <p>
            Après, une formation en Manucure Russe en Août 2022 et la validation
            de mon CAP esthétique, je me suis entrainée pendant plus d’un an
            pour parfaire ma technique et proposer des prestations de qualité.
          </p>

          <p>
            Je suis ravie de vous accueillir dans mon petit univers, situé à mon
            domicile, pour vous offrir bien plus qu'une simple pose d'ongles :
            un véritable moment de bien-être et de mise en beauté de vos mains.
          </p>
        </div>

        <div className="button-container">
          <a
            href="https://www.instagram.com/sarah.manucure/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="home-button">
              <i
                className="fa-brands fa-instagram home-icon"
                aria-hidden="true"
              ></i>
              <span>Voir mon instagram</span>
            </Button>
          </a>
          <a
            href="https://calendly.com/sarahmanucure"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="home-button">
              <i className="fa-light fa-calendar-check home-icon"></i>
              <span>Prenez rendez-vous</span>
            </Button>
          </a>
        </div>
      </div>

      <div className="image-zone">
        <img
          src={Homepic}
          alt="Sarah Manucure assise à son bureau pendant une prestation"
          loading="lazy"
        />
        <h2 className="about-name">Sarah G.</h2>
        <p className="about-subtext">
          Formée par Chloé Hoffner
          <br />
          en Manucure Russe
        </p>
      </div>
    </div>
  )
}

export default Home
