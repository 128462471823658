import React, { useState } from 'react'
import PropTypes from 'prop-types'
import supplementData from '../../../assets/data/supplement.json'
import './style.scss'

function PrestaModal({ show, onClose }) {
  const [currentPicture, setCurrentPicture] = useState(0)
  function changePicture(direction) {
    if (direction === 'next') {
      setCurrentPicture(
        (prevPicture) => (prevPicture + 1) % supplementData.length
      )
    } else if (direction === 'previous') {
      setCurrentPicture((prevPicture) =>
        prevPicture === 0 ? supplementData.length - 1 : prevPicture - 1
      )
    }
  }

  if (!show) return false

  const handleBackgroundClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      onClose()
    }
  }

  return (
    <div className="modal-overlay" onClick={handleBackgroundClick}>
      <div className="modal-content-presta">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>

        {supplementData.length > 1 && (
          <i
            className="fa-sharp fa-light fa-circle-arrow-left left-arrow arrow"
            onClick={() => changePicture('previous')}
          ></i>
        )}

        <img
          src={require(`../../../${supplementData[currentPicture].image.src}`)}
          alt={supplementData[currentPicture].alt}
          className="carousel-image"
        />

        <div className="supplement-details">
          <h3>{supplementData[currentPicture].nom}</h3>
          <p>{supplementData[currentPicture].prix}</p>
          <p>{supplementData[currentPicture].duree}</p>
        </div>

        {supplementData.length > 1 && (
          <i
            className="fa-sharp fa-regular fa-circle-arrow-right right-arrow arrow"
            onClick={() => changePicture('next')}
          ></i>
        )}
      </div>
    </div>
  )
}

PrestaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PrestaModal
