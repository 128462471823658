import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

function Button({ children, onClick, className = '' }) {
  return (
    <button className={`custom-button ${className}`} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Button
