import React from 'react'
import Slider from '../Slider'
import Button from '../Button'
import './style.scss'

function About() {
  return (
    <div className="container about-page">
      <div className="text-zone">
        <h1>Qu'est ce que la Manucure Russe ?</h1>

        <p className="about-text">
          Technique qui vise à travailler en profondeur la cuticule. C'est à
          dire à la soulever et la couper. Cette technique doit être réalisé
          dans des conditions d'hygiène parfaite. Avec un materiel nettoyé,
          désinfecté et stérilisé. Ce qui permet de poser le gel et la couleur
          sous cuticule.
        </p>

        <h2>Les plus de cette technique</h2>
        <ul>
          <li>Une manucure soignée, propre et nette.</li>
          <li>
            Gagner en temps de repousse visuellement, grâce à l'application sous
            cuticule du gel et de la couleur.
          </li>
          <li>
            Une peau et des ongles sains, les ongles poussent plus facilement et
            en meilleure santé.
          </li>
        </ul>

        <h2>Comment ça marche ?</h2>
        <p>
          La cuticule est doucement soulevée, nettoyée et coupée pour préparer
          l'ongle à une pose de gel ou de vernis impeccable.
        </p>

        <a
          href="https://calendly.com/sarahmanucure"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="about-button">
            <i className="fa-light fa-calendar-check"></i>
            <span>Prenez rendez-vous</span>
          </Button>
        </a>
      </div>

      <div className="slider-zone">
        <Slider />
      </div>
    </div>
  )
}

export default About
