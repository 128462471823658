import React, { useState, useEffect } from 'react'
import './style.css'

function Slider() {
  const [sliderPosition, setSliderPosition] = useState(50)

  useEffect(() => {
    document.documentElement.style.setProperty('--slider-value', '51%')
  }, [])

  const handleSliderChange = (e) => {
    const newPosition = e.target.value
    setSliderPosition(newPosition)
    document.documentElement.style.setProperty(
      '--slider-value',
      `${newPosition}%`
    )
  }

  return (
    <div className="slider">
      <div className="slider-guide-bar-back"></div>
      <div className="slider-guide-bar"></div>
      <div className="slider-before image-container">
        <span className="label before-label">Avant</span>
        <img
          src={require('../../assets/images/avantManucure2.webp')}
          alt="Avant manucure"
          className="image before-image"
        />
      </div>

      <div className="slider-after image-container">
        <span className="label after-label">Après</span>
        <img
          src={require('../../assets/images/apresManucure2.webp')}
          alt="Après manucure"
          className="image after-image"
        />
      </div>

      <div className="slider-separator">
        <div className="slider-line">
          <div className="slider-circle"></div>
        </div>
      </div>

      <input
        className="slider-range"
        type="range"
        min="0"
        max="100"
        value={sliderPosition}
        onChange={handleSliderChange}
      />
    </div>
  )
}

export default Slider
