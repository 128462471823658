import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import { useEffect } from 'react'
import Layout from './components/Layout'
import Home from './components/Home'
import About from './components/About'
import Prestation from './components/Prestation'
import Reviews from './components/Reviews'
import Contact from './components/Contactmap'
import './App.scss'

function RedirectToHome() {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return null
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="information" element={<About />} />
          <Route path="rendez-vous" element={<Prestation />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<RedirectToHome />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
