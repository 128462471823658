import { MapContainer, Popup, TileLayer, Marker } from 'react-leaflet'
import Button from '../Button'
import './style.scss'

function Contact() {
  return (
    <div className="container contact-page">
      <div className="text-zone">
        <h1>Contact</h1>

        <a href="tel:+33745161530">
          <Button className="contact-button phone-number">
            <i
              className="fa-regular fa-phone contact-icon"
              aria-hidden="true"
            ></i>
            <span>Appelez moi: 07.45.16.15.30</span>
          </Button>
        </a>

        <a
          href="https://www.instagram.com/sarah.manucure/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="contact-button">
            <i
              className="fa-brands fa-instagram contact-icon"
              aria-hidden="true"
            ></i>
            <span>Voir mon instagram</span>
          </Button>
        </a>
      </div>

      <div className="info-map">
        Sarah Pol,
        <br />
        France
        <br />
        2 impasse César Rossi, 38450
        <br />
        Saint-Georges-de-commiers
      </div>

      <div className="map-wrap">
        <MapContainer
          className="leaflet-container"
          center={[45.042983534391134, 5.7009191725650945]}
          zoom={17}
          scrollWheelZoom={false}
          dragging={false}
          doubleClickZoom={false}
          zoomControl={false}
          touchZoom={false}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[45.042983534391134, 5.7009191725650945]}>
            <Popup>Salon de Sarah Manucure</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  )
}

export default Contact
